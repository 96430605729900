import * as React from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button, Typography, Box
} from '@mui/material';

import { useDropzone } from 'react-dropzone';

type UploadDialogProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (files: File[]) => void;
};

export default function UploadDialog({
  open,
  onClose,
  onSubmit
}: UploadDialogProps) {
  const [files, setFiles] = React.useState<File[]>([]);

  const onDrop = React.useCallback((acceptedFiles: File[]) => {
    setFiles(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleSubmit = () => {
    onSubmit(files);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Upload file</DialogTitle>
      <DialogContent>
        <Box
          {...getRootProps()}
          sx={{
            border: '2px dashed grey',
            borderRadius: 1,
            textAlign: 'center',
            p: 3,
            mb: 2,
            cursor: 'pointer',
            bgcolor: isDragActive ? '#fafafa' : 'transparent'
          }}
        >
          <input {...getInputProps()} />
          <Typography variant="h6" gutterBottom>
            Drag and drop a file here or click
          </Typography>
          <Box>
            <span style={{ fontSize: 50 }}><CloudUploadIcon sx={{ fontSize: 50 }} /></span>
          </Box>
        </Box>

        {files.map((file) => (
          <Typography key={file.name}>{file.name}</Typography>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>CANCEL</Button>
        <Button onClick={handleSubmit} variant="contained">SUBMIT</Button>
      </DialogActions>
    </Dialog>
  );
}
