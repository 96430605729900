import {FormHintExplanation, InputWidget, Widget} from "../../../models/contents.ts"
import {FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField,} from "@mui/material"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import {useState} from "react"
import {InputWidgetFormForm} from "./input-widget-form-form.tsx"
import {InputFromImage} from "../../misc/input-from-image.tsx"
import {InputWidgetFormEqFull} from "./input-widget-form-eq-full.tsx"
import {InputWidgetFormEqPartial} from "./input-widget-form-eq-partial.tsx"
import HintExplanationForm from "./hint-explanation-form.tsx"
import {QuestionsOption} from "../../misc/questions-option.tsx"
import {HintExplanationFormCopyPastePanel} from "./hint-explanation-form-copy-paste-panel.tsx";

function InputWidgetForm(props: {
  value: InputWidget
  onChange: (value: Widget) => void
  isQuestion: boolean
}) {
  if (props.value.form === undefined) {
    props.value.form = {
      template: "Manual",
      no: 0,
      extension: "webp",
      original: {
        w: 0,
        h: 0,
      },
      display: {
        w: 0,
      },
      input: [],
      additional_texts: [],
      is_multiple_answers: false,
    } as InputWidget.FormManual
    props.onChange(props.value)
    return <></>
  }
  const form = props.value.form!

  const changeForm = (value: InputWidget.Form) => {
    const newValue = { ...props.value }
    newValue.form = value
    props.onChange(newValue)
    if (value.template !== "Manual" && tabKey === "additional") {
        setTabKey("base")
    }
  }

  const showForm = () => {
    switch (form.template) {
      case "EqFull":
        return (
          <InputWidgetFormEqFull value={form as InputWidget.FormEqFull} onChange={changeForm} />
        )
      case "EqPartial":
        return (
          <InputWidgetFormEqPartial
            value={form as InputWidget.FormEqPartial}
            onChange={changeForm}
          />
        )
      default:
        return (
          <InputWidgetFormForm
            value={form as InputWidget.FormManual}
            params={props.value?.parameters}
            onChange={changeForm}
            widgetId={props.value.id}
          />
        )
    }
  }

  type TabKey = "base" | "additional" | "hint"
  const [tabKey, setTabKey] = useState<TabKey>("base")
  const handleTabChange = (_event: React.SyntheticEvent, newValue: TabKey) => {
    setTabKey(newValue)
  }

  return (
    <>
      <Grid item xs={2}>
        <TextField fullWidth label="type" value={props.value?.type} disabled={true} />
      </Grid>
      <Grid item xs={2}>
        <FormControl size="small">
          <InputLabel id={`template-${props.value.id}`}>template</InputLabel>
          <Select
            labelId={`template-${props.value.id}`}
            label="type"
            onChange={(event) => {
              const template = event.target.value
              if (template === "Manual") {
                changeForm({
                  template: "Manual",
                  no: 0,
                  extension: "webp",
                  original: {
                    w: 0,
                    h: 0,
                  },
                  display: {
                    w: 0,
                  },
                  input: [],
                  additional_texts: [],
                  hint: form.hint,
                  explanation: form.explanation,
                  is_multiple_answers: false,
                } as InputWidget.FormManual)
              } else if (template === "EqFull") {
                changeForm({
                  template: "EqFull",
                  answer1: {
                    answer: "",
                  },
                  answer2: {
                    answer: "",
                  },
                  answer3: {
                    answer: "",
                  },
                  answer4: {
                    answer: "",
                  },
                  answer5: {
                    answer: "",
                  },
                  hint: form.hint,
                  explanation: form.explanation,
                } as InputWidget.FormEqFull)
              } else if (template === "EqPartial") {
                changeForm({
                  template: "EqPartial",
                  answer1: {
                    answer: "",
                  },
                  answer2: {
                    answer: "",
                  },
                  answer3: {
                    answer: "",
                  },
                  hint: form.hint,
                  explanation: form.explanation,
                } as InputWidget.FormEqPartial)
              }
            }}
            value={props.value.form?.template || "Manual"}
          >
            <MenuItem value="Manual">
              <em>Manual</em>
            </MenuItem>
            <MenuItem value="EqFull">EqFull</MenuItem>
            <MenuItem value="EqPartial">EqPartial</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {props.isQuestion ? (
          <QuestionsOption value={props.value} onChange={props.onChange}/>
      ) : (
        <></>
      )}
      <Grid item xs={props.isQuestion ? 3 : 8}>
        {!props.value.form?.template || props.value.form?.template === "Manual" ? (
          <InputFromImage
            onExtract={(value) => {
              changeForm({
                ...form,
                extension: value.extension,
                original: {
                  w: value.w,
                  h: value.h,
                },
              } as InputWidget.FormManual)
            }}
          />
        ) : (
          <></>
        )}
      </Grid>
      <TabContext value={tabKey}>
        <Grid item xs={12}>
          <TabList onChange={handleTabChange}>
            <Tab label="Base" value="base" />
            <Tab
              label="Additional Text / Input"
              value="additional"
              sx={{ display: (props.value.form?.template == undefined || props.value.form?.template === "Manual") ? "block" : "none"}}
            />
            <Tab label="Hint / Explanation" value="hint" />
          </TabList>
        </Grid>
        {showForm()}
        <TabPanel value="hint">
          <Stack spacing={2}>
            <HintExplanationForm
              widgetId={`hint-${props.value.id}`}
              title="Hint"
              value={form.hint}
              onChange={(value: FormHintExplanation) => {
                changeForm({
                  ...form,
                  hint: value,
                })
              }}
            />
            <HintExplanationForm
              widgetId={`explanation-${props.value.id}`}
              title="Explanation"
              value={form.explanation}
              onChange={(value: FormHintExplanation) => {
                changeForm({
                  ...form,
                  explanation: value,
                })
              }}
            />
            <HintExplanationFormCopyPastePanel
              hint={form.hint}
              explanation={form.explanation}
              onPaste={(hint, explanation) => {
                changeForm({
                  ...form,
                  hint: hint,
                  explanation: explanation,
                })
              }}
            />
          </Stack>
        </TabPanel>
      </TabContext>
    </>
  )
}

export default InputWidgetForm
