import UploadDialog from './upload-dialog';
import {ElementNode, parse} from "svg-parser";
import sizeOf from "buffer-image-size";
import {Button} from "@mui/material";
import {useState} from "react";
import {Buffer} from "buffer";

export const InputFromImage = (props: {
    onExtract: (value: {
        w: number,
        h: number,
        extension: string,
        fileName: string,
    }) => void,
}) => {
    window.Buffer = window.Buffer || Buffer
    const [dialog, setDialog] = useState(false)
    const handleSubmit = async (files: File[]) => {
        console.log('Files to submit:', files);
        {
            try {
                const file = files[0]
                if (file.type === "image/svg+xml") {
                    const text = await file.text()
                    const svg = parse(text)
                    const node = svg.children[0] as ElementNode
                    const viewBox = (node.properties!.viewBox! as string).split(" ").map((s) => Number(s))
                    props.onExtract({
                        w: viewBox[2] - viewBox[0],
                        h: viewBox[3] - viewBox[1],
                        extension: "svg",
                        fileName: file.name,
                    })
                    return
                } else {
                    const ab = await file.arrayBuffer()
                    const img = sizeOf(Buffer.from(ab))
                    return props.onExtract({
                        w: img.width || 0,
                        h: img.height || 0,
                        extension: file.type.split("/")[1],
                        fileName: file.name,
                    })
                }
            } catch (e) {
                console.error(e)
            } finally {
                setDialog(false)
            }
        }
      };
    return (
        <>
            <Button variant="contained" onClick={() => setDialog(true)}>input from image</Button>
            <UploadDialog
                open={dialog}
                onClose={() => setDialog(false)}
                onSubmit={handleSubmit}
            />
        </>
    )
}
