import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton, MenuItem, Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import TabPanel from "@mui/lab/TabPanel"
import {Fragment} from "react"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import {VoiceIdInput} from "../../misc/voice-id-input.tsx"
import InputTextBoxForm from "./input-text-box-form.tsx"
import AdditinalTextForm from "./additinal-text-form.tsx"
import {Thumbnail} from "../../misc/thumbnail.tsx";
import {ArrowDownward, ArrowUpward} from "@mui/icons-material"
import {SvgPicker} from "./svg-picker.tsx";
import {InputWidget} from "../../../models/contents.ts";

export function InputWidgetFormForm(props: {
  widgetId: string
  params: InputWidget.Parameter | undefined
  value: InputWidget.FormManual | undefined
  onChange: (value: InputWidget.Form) => void
}) {
  if (props.value === undefined) {
    props.onChange({
      template: "Manual",
      no: 0,
      extension: "webp",
      original: {
        w: 0,
        h: 0,
      },
      display: {
        w: 0,
      },
      input: [],
      additional_texts: [],
      is_multiple_answers: false,
      multiple_answers: "",
    } as InputWidget.FormManual)
    return <></>
  }
  const form = props.value!

  const setForm = (newForm: InputWidget.Form) => {
    props.onChange(newForm)
  }

  const addInputTextBox = (idx: number) => {
    const newForm = { ...form }
    newForm.input.splice(idx, 0, {
      x: 0,
      y: 0,
      type: "general",
      answer: "",
      relative_h: 0,
      relative_w: 0,
    })
    setForm(newForm)
  }

  const moveInputTextBox = (idx: number, direction: number) => {
    const newForm = { ...form }
    const target = newForm.input[idx]
    newForm.input[idx] = newForm.input[idx + direction]
    newForm.input[idx + direction] = target
    setForm(newForm)
  }

  const changeInputTextBox = (idx: number, value: InputWidget.FormTextBox) => {
    const newForm = { ...form }
    newForm.input[idx] = value
    setForm(newForm)
  }

  const deleteInputTextBox = (idx: number) => {
    const newForm = { ...form }
    newForm.input.splice(idx, 1)
    setForm(newForm)
  }

  const addAdditionalText = (idx: number) => {
    const newForm = { ...form }
    if (newForm.additional_texts === undefined) newForm.additional_texts = []
    newForm.additional_texts.splice(idx, 0, {
      text: "",
      x: 0,
      y: 0,
      font_size: 0,
      align: "center",
    })
    setForm(newForm)
  }

  const addFormTextBox = (box: InputWidget.FormTextBox) => {
    const newForm = { ...form }
    newForm.input.push(box)
    setForm(newForm)
  }

  const moveAdditionalText = (idx: number, direction: number) => {
    if (!form.additional_texts) return
    const newForm = { ...form }
    const target = newForm.additional_texts![idx]
    newForm.additional_texts![idx] = newForm.additional_texts![idx + direction]
    newForm.additional_texts![idx + direction] = target
    setForm(newForm)
  }

  const changeAdditionalText = (idx: number, value: InputWidget.AdditionalText) => {
    const newForm = { ...form }
    if (newForm.additional_texts === undefined) newForm.additional_texts = []
    newForm.additional_texts[idx] = value
    setForm(newForm)
  }

  const deleteAdditionalText = (idx: number) => {
    const newForm = { ...form }
    if (newForm.additional_texts === undefined) newForm.additional_texts = []
    newForm.additional_texts.splice(idx, 1)
    setForm(newForm)
  }

  const validateMultipleAnswers = () => {
    const multipleAnswers = form.multiple_answers
    if (!form.is_multiple_answers || !multipleAnswers) {
      return true
    }
    const answerList = multipleAnswers.split("\n")
    return answerList.every((value) => {
      const answers = value.split(",")
      return answers.length === form.input.length
    })
  }

  return (
    <>
      <TabPanel value="base">
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <TextField
              fullWidth
              label="text"
              value={form.text ?? ""}
              onChange={(event) => {
                setForm({
                  ...form,
                  text: event.target.value ? event.target.value : undefined,
                })
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={form.is_markdown ?? false}
                  onChange={(event) => {
                    setForm({
                      ...form,
                      is_markdown: event.target.checked,
                    })
                  }}
                />
              }
              label="is markdown"
            />
          </Grid>
          <Grid item xs={2}>
            <VoiceIdInput
              id={props.widgetId}
              onChange={(value) => {
                props.onChange({
                  ...form,
                  voice_id: value,
                })
              }}
              value={props.value?.voice_id ?? ""}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              multiline
              fullWidth
              label="voice text"
              value={props.value.voice_text}
              onChange={(event) => {
                setForm({...form, voice_text: event.target.value})
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={form.no_icon ?? false}
                  onChange={(event) => {
                    setForm({
                      ...form,
                      no_icon: event.target.checked,
                    })
                  }}
                />
              }
              label="no icon"
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              label="no"
              type="number"
              value={form.no || 0}
              onChange={(event) => {
                setForm({
                  ...form,
                  no: parseInt(event.target.value),
                })
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              label="extension"
              value={form.extension}
              onChange={(event) => {
                setForm({
                  ...form,
                  extension: event.target.value,
                })
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              label="width when displayed"
              type="number"
              value={form.display.w}
              onChange={(event) => {
                setForm({
                  ...form,
                  display: {
                    ...form.display,
                    w: parseInt(event.target.value),
                  },
                })
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={form.allow_reduction ?? false}
                  onChange={(event) => {
                    setForm({
                      ...form,
                      allow_reduction: event.target.checked,
                    })
                  }}
                />
              }
              label="allow reduction"
            />
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={form.set_image_url ?? false}
                  onChange={(event) => {
                    let image_url = form.image_url
                    if (!event.target.checked) {
                      image_url = undefined
                    }
                    setForm({
                      ...form,
                      image_url,
                      set_image_url: event.target.checked,
                    })
                  }}
                />
              }
              label="set Image URL"
            />
          </Grid>
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardContent>
                <Typography color="#9E9E9E">Original</Typography>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      label="width"
                      value={form.original.w}
                      onChange={(event) => {
                        setForm({
                          ...form,
                          original: {
                            ...form.original,
                            w: parseInt(event.target.value),
                          },
                        })
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      label="height"
                      value={form.original.h}
                      onChange={(event) => {
                        setForm({
                          ...form,
                          original: {
                            ...form.original,
                            h: parseInt(event.target.value),
                          },
                        })
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="file path"
                      disabled={!form.set_image_url}
                      value={form?.image_url ?? props.params?.image_url ?? ""}
                      onChange={(event) => {
                        setForm({
                          ...form,
                          image_url: event.target.value,
                        })
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    {props.params?.image_url && <Thumbnail url={props.params?.image_url} />}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value="additional">
        <Grid item xs={12} sx={{ ml: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AddAdditional
                idx={0}
                addAdditionalText={addAdditionalText}
                moveAdditionalText={moveAdditionalText}
                itemsLength={form.additional_texts?.length ?? 0}
              />
            </Grid>
            {form.additional_texts?.map((at, idx) => (
              <Fragment key={idx}>
                <Grid item>
                  <AdditinalTextForm
                    widgetId={props.widgetId}
                    idx={idx}
                    value={at}
                    onChange={changeAdditionalText}
                    onDelete={deleteAdditionalText}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AddAdditional
                    idx={idx + 1}
                    addAdditionalText={addAdditionalText}
                    moveAdditionalText={moveAdditionalText}
                    itemsLength={form.additional_texts?.length ?? 0}
                  />
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </Grid>
          <SvgPicker url={props.params?.image_url} addFormTextBox={addFormTextBox}/>
        <Grid item xs={12} sx={{ ml: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Select
                fullWidth
                label="multiple_answers"
                value={form.is_multiple_answers ? "multiple" : "general"}
                onChange={(event) => {
                  const value = event.target.value
                  props.onChange({
                    ...form,
                    is_multiple_answers: value === "multiple",
                  })
                }}
              >
                <MenuItem value="general">General</MenuItem>
                <MenuItem value="multiple">Multiple Answer</MenuItem>
              </Select>
            </Grid>
            <Grid item>
              <AddInputTextBox
                idx={0}
                addInputTextBox={addInputTextBox}
                moveInputTextBox={moveInputTextBox}
                itemsLength={form.input.length}
              />
            </Grid>
            {form.input.map((input, idx) => (
              <Fragment key={idx}>
                <Grid item>
                  <InputTextBoxForm
                    widgetId={props.widgetId}
                    idx={idx}
                    value={input}
                    isMultipleAnswerType={form.is_multiple_answers}
                    onChange={changeInputTextBox}
                    onDelete={deleteInputTextBox}
                  />
                </Grid>
                <Grid item>
                  <AddInputTextBox
                    idx={idx + 1}
                    addInputTextBox={addInputTextBox}
                    moveInputTextBox={moveInputTextBox}
                    itemsLength={form.input.length}
                  />
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ ml: 4 }} hidden={!props.value.is_multiple_answers}>
          <Grid item>
            <TextField
              fullWidth
              label="answers"
              multiline
              value={form.multiple_answers}
              error={!validateMultipleAnswers()}
              onChange={(event) => {
                props.onChange({
                  ...form,
                  multiple_answers: event.target.value,
                })
              }}
            />
            カンマ区切りで回答を入力してください。<br/>Inputの数と一致する必要があります。<br/>最初の回答がAnswerとして表示されます。
          </Grid>
        </Grid>
      </TabPanel>
    </>
  )
}

function AddInputTextBox(prop: {
  idx: number
  addInputTextBox: (idx: number) => void
  moveInputTextBox: (idx: number, direction: number) => void
  itemsLength: number
}) {
  return (
    <>
      <Stack direction="row">
        <Button onClick={() => prop.addInputTextBox(prop.idx)} sx={{ textAlign: "center" }}>
          <AddCircleOutlineIcon />
          <Typography sx={{ ml: 1 }}>Add Input</Typography>
        </Button>
        <IconButton
          size="small"
          color="primary"
          disabled={prop.idx === 0}
          onClick={() => prop.moveInputTextBox(prop.idx, -1)}
          sx={{ display: prop.idx === prop.itemsLength ? "none" : "block" }}
        >
          <ArrowUpward />
        </IconButton>
        <IconButton
          size="small"
          color="primary"
          disabled={prop.idx === prop.itemsLength - 1}
          onClick={() => prop.moveInputTextBox(prop.idx, 1)}
          sx={{ display: prop.idx === prop.itemsLength ? "none" : "block" }}
        >
          <ArrowDownward />
        </IconButton>
      </Stack>
    </>
  )
}

function AddAdditional(prop: {
  idx: number
  addAdditionalText: (idx: number) => void
  moveAdditionalText: (idx: number, direction: number) => void
  itemsLength: number
}) {
  return (
    <>
      <Stack direction="row">
        <Button onClick={() => prop.addAdditionalText(prop.idx)} sx={{ textAlign: "center" }}>
          <AddCircleOutlineIcon />
          <Typography sx={{ ml: 1 }}>Add Additional Text</Typography>
        </Button>
        <IconButton
          size="small"
          color="primary"
          disabled={prop.idx === 0}
          onClick={() => prop.moveAdditionalText(prop.idx, -1)}
          sx={{ display: prop.idx === prop.itemsLength ? "none" : "block" }}
        >
          <ArrowUpward />
        </IconButton>
        <IconButton
          size="small"
          color="primary"
          disabled={prop.idx === prop.itemsLength - 1}
          onClick={() => prop.moveAdditionalText(prop.idx, 1)}
          sx={{ display: prop.idx === prop.itemsLength ? "none" : "block" }}
        >
          <ArrowDownward />
        </IconButton>
      </Stack>
    </>
  )
}
