import {
    CategoryWidget,
    ChoiceWidget,
    Contents,
    DialogueWidget,
    HandWritingWidget,
    HandWritingV2Widget,
    ImageWidget,
    InputWidget,
    MultipleChoiceWidget,
    PauseWidget,
    DividerWidget,
    TextWidget,
    ThreeDimensionalImageWidget,
    TitleWidget,
    VideoWidget,
    Widget,
    WidgetType,
    ActionWidget,
} from "../../models/contents.ts";
import TitleWidgetForm from "../widget-form/title-widget-form.tsx";
import CategoryWidgetForm from "../widget-form/category-widget-form.tsx";
import ImageWidgetForm from "../widget-form/image-widget-form.tsx";
import VideoWidgetForm from "../widget-form/video-widget-form.tsx";
import DialogueWidgetForm from "../widget-form/dialogue-widget-form.tsx";
import InputWidgetForm from "../widget-form/input-widget-form/input-widget-form.tsx";
import ChoiceWidgetForm from "../widget-form/choice-widget-form.tsx";
import {MultipleChoiceWidgetForm} from "../widget-form/multiple-choice-widget-form.tsx";
import {useMemo} from "react";
import HandWritingWidgetForm from "../widget-form/hand-writing-widget-form.tsx";
import HandWritingV2WidgetForm from "../widget-form/hand-writing-v2-widget-form.tsx";
import TextWidgetForm from "../widget-form/text-widget-form.tsx";
import PauseWidgetForm from "../widget-form/pause-widget-form.tsx";
import DividerWidgetForm from "../widget-form/divider-widget-form.tsx";
import ThreeDimensionalImageWidgetForm from "../widget-form/three-dimensional-image-widget-form.tsx";
import ActionWidgetForm from "../widget-form/action-widget-form.tsx";

function LessonsWidgetForm(props: {
    widget: Widget,
    onChange: (value: Widget) => void,
    contents: Contents,
}) {
    const {widget, onChange} = props
    return useMemo(() => {
        switch (widget.type) {
            case WidgetType.Title:
                return (
                    <TitleWidgetForm value={widget as TitleWidget} onChange={onChange}/>
                )
            case WidgetType.Category:
                return (
                    <CategoryWidgetForm value={widget as CategoryWidget} onChange={onChange}/>
                )
            case WidgetType.Image:
                return (
                    <ImageWidgetForm value={widget as ImageWidget} onChange={onChange}/>
                )
            case WidgetType.Video:
                return (
                    <VideoWidgetForm value={widget as VideoWidget} onChange={onChange}/>
                )
            case WidgetType.Dialogue:
                return (
                    <DialogueWidgetForm value={widget as DialogueWidget} onChange={onChange}/>
                )
            case WidgetType.Input:
                return (
                    <InputWidgetForm value={widget as InputWidget} onChange={onChange} isQuestion={false}/>
                )
            case WidgetType.Choice:
                return (
                    <ChoiceWidgetForm value={widget as ChoiceWidget} onChange={onChange}/>
                )
            case WidgetType.MultipleChoice:
                return (
                    <MultipleChoiceWidgetForm value={widget as MultipleChoiceWidget} onChange={onChange}
                                              isQuestion={false}/>
                )
            case WidgetType.HandWriting:
                return (
                    <HandWritingWidgetForm value={widget as HandWritingWidget} onChange={onChange} isQuestion={false}/>
                )
            case WidgetType.HandWritingV2:
                return (
                    <HandWritingV2WidgetForm value={widget as HandWritingV2Widget} onChange={onChange} isQuestion={false}/>
                )
            case WidgetType.Text:
                return (
                    <TextWidgetForm value={widget as TextWidget} onChange={onChange}/>
                )
            case WidgetType.Pause:
                return (
                    <PauseWidgetForm value={widget as PauseWidget} />
                )
            case WidgetType.Divider:
                return (
                    <DividerWidgetForm value={widget as DividerWidget} />
                )
            case WidgetType.ThreeDimensionalImage:
                return (
                  <ThreeDimensionalImageWidgetForm value={widget as ThreeDimensionalImageWidget} onChange={onChange}/>
                )
            case WidgetType.Action:
                return (
                  <ActionWidgetForm value={widget as ActionWidget} onChange={onChange}/>
                )
            default:
                return <></>
        }
    }, [widget,
        props.contents.grade,
        props.contents.unit,
        props.contents.lesson,
    ])
}

export default LessonsWidgetForm
