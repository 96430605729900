import {useContext, useEffect, useState} from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent
} from "@mui/material";
import UseTemplateIcon from "@mui/icons-material/Bookmark";
import {Template, TemplatesContext} from "../../context/template.tsx";
import UploadDialog from './upload-dialog';

function UseTemplate(props: {
    idx: number
}) {
    const {
        templates,
        setTemplates,
        addTemplateWidget,
    } = useContext(TemplatesContext)

    const [selectedTemplate, setSelectedTemplate] = useState<Template>(() => {
        const loaded = localStorage.getItem("template");
        if (loaded) {
            return JSON.parse(loaded) as Template;
        }
        return {} as Template;
    });

    useEffect(() => {
        localStorage.setItem("template", JSON.stringify(selectedTemplate));
    }, [selectedTemplate]);
    
    const [useTemplateDialogOpen, setUseTemplateDialogOpen] = useState(false);
    const [loadDialogOpen, setLoadDialogOpen] = useState(false);


    const deleteTemplate = () => {
        if (setTemplates === undefined || selectedTemplate === undefined) return

        let newTemplates = [...templates]
        let index = newTemplates.findIndex((t) => t.id === selectedTemplate.id)
        newTemplates.splice(index, 1)
        setTemplates(newTemplates)
    }

    // after selecting a name, we select the template corresponding to the name
    const handleChange = (event: SelectChangeEvent) => {
        const title = event.target.value
        const foundTemplate = templates.find(template => template.title === title)
        if (foundTemplate) {
   	       setSelectedTemplate(foundTemplate)
        }
    }
    
    const exportTemplate = () => {
        if (selectedTemplate) {
            const blob = new Blob([JSON.stringify(selectedTemplate, null, 2)], {type: "application/json"})
            const url = URL.createObjectURL(blob)
            const a = document.createElement("a")
            a.href = url
            a.download = `${selectedTemplate.title}.json`
            a.click()
        }
    }

     // Load a json file into contents
     const load = (template: Template) => {
        if (!templates.find(t => t.id === template.id)){
            setTemplates([...templates, template]);
        }
    };

    return (
        <>
            <IconButton 
                size="small" 
                color="primary" 
                onClick={() => {
                    setUseTemplateDialogOpen(true)
                }}
            ><UseTemplateIcon /></IconButton>
            <Dialog 
                open={useTemplateDialogOpen} 
                onClose={ () => {
                    setUseTemplateDialogOpen(false)}}
            >
                <DialogTitle>
                    Templates
                    <Box display="end" justifyContent="flex-end">
                        <Button onClick={() => {
                            setLoadDialogOpen(true)
                            }}>Load
                        </Button>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <InputLabel id="select">Select Template</InputLabel>
                        <Select
                            labelId="label"
                            id="select"
                            value={templates.some(t => t.title === selectedTemplate?.title) ? selectedTemplate?.title : ""}
                            label="Select Template"
                            onChange={handleChange}
                        >
                            <MenuItem aria-label="None" value=""/>
                            {templates.map(template => (
                                <MenuItem key={template.title} value={template.title}>{template.title}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setUseTemplateDialogOpen(false)
                    }}>Cancel</Button>
                    <Button onClick={() => {
                        deleteTemplate()
                        setUseTemplateDialogOpen(false)
                    }}>Delete</Button>
                    <Button variant="contained" onClick={() => {
                        if (selectedTemplate) {
                            addTemplateWidget(props.idx, selectedTemplate) // Pass both idx and selectedTemplate
                            setUseTemplateDialogOpen(false)
                        }
                    }}>Use</Button>
                    <Button variant="contained" onClick={() => {
                        if (selectedTemplate) {
                            exportTemplate();
                            setLoadDialogOpen(false);
                            setUseTemplateDialogOpen(false);
                        }
                    }}>Export</Button>
                </DialogActions>
            </Dialog>
            <UploadDialog
                open={loadDialogOpen}
                onClose={() => {
                    setLoadDialogOpen(false);
                }}
                onSubmit={(files) => {
                    const file = files[0];
                    file.text().then((text) => {
                        load(JSON.parse(text));
                        setLoadDialogOpen(false);
                    }).catch((error) => {
                        console.error("Error loading template: ", error);
                        setLoadDialogOpen(false);
                    });
                }}
            />
        </>
    );
}

export default UseTemplate;
