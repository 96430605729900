import {ActionType, ActionWidget, Widget} from "../../models/contents.ts";
import {FormControl,  Grid, InputLabel, MenuItem, Select, TextField} from "@mui/material";

function ActionWidgetForm(props: {
  value: ActionWidget
  onChange: (value: Widget) => void
}) {
  if (props.value.form === undefined) {
    props.value.form = {
      type: "fixed_end",
    }
    props.onChange(props.value)
    return <></>
  }
  const form = props.value.form!
  return (
    <>
      <Grid item xs={3}>
        <TextField
          fullWidth
          label="type"
          value={props.value.type}
          disabled={true}
        />
      </Grid>
      <Grid item xs={9}>
        <FormControl fullWidth>
          <InputLabel id="type">Type</InputLabel>
          <Select
            labelId="type"
            label="Type"
            onChange={(event) => {
              const newValue = {...props.value}
              newValue.form!.type = event.target.value as ActionType
              props.onChange(newValue)
            }}
            defaultValue={form.type}
          >
            <MenuItem value="fixed_end">fixedEnd</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </>
  );
}

export default ActionWidgetForm;
