import {useContext} from "react";
import {ContentContext} from "../lessons-generator/lessons-generator.tsx";
import {UrlHelper} from "../../util/url-helper.ts";
import '@google/model-viewer';

export function ModelViewer(props: {
  url: string,
  h: number,
  bg_color: string,
}) {
  const content = useContext(ContentContext)
  const modelSrc = UrlHelper.normalizeUrl(props.url, content)
  { /* @ts-expect-error - model-viewer is not a valid HTML element, but it is a valid custom element */}
  return <model-viewer src={modelSrc}
    alt="A 3D model"
    auto-rotate
    camera-controls
    style={{width: "100%", height: `${props.h}px`, background: `${props.bg_color}`}}
  />
}