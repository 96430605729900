import {
    AppBar,
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogTitle, IconButton, PropTypes,
    Stack,
    Toolbar,
    Typography,
} from "@mui/material";
import UploadDialog from './upload-dialog';
import QRCode from "react-qr-code";
import {createRef, useContext, useState} from "react";
import LinkDialogue, {LinkDialogueRef} from "./link-dialogue.tsx";
import { Contents } from "../../models/contents.ts";
import { Questions } from "../../models/Questions.ts";
import { isContents, UrlHelper } from "../../util/url-helper.ts";
import MenuIcon from '@mui/icons-material/Menu';
import { ExplorerContext } from "../../context/explorer.tsx";

function CommonAppBar(props: {
    title: string,
    color: PropTypes.Color,
    load: (contents: any) => void,
    clear: () => void,
    exportContents: () => void,
    previewContents: () => void,
    previewUrl: string | undefined,
    data: Contents | Questions | undefined,
}) {
    const [dialog, setDialog] = useState("none");
    const linkDialogueRef = createRef<LinkDialogueRef>();

    const {
        open,
        toggleExplorer,
    } = useContext(ExplorerContext)
    
    const closeDialog = () => {
        setDialog("none")
    }

    const linkBaseUrl = `${location.protocol}//${location.host}`
    const previewLinkUrl = props.previewUrl
        ? `${linkBaseUrl}${location.pathname}?ct=${encodeURIComponent(props.previewUrl)}`
        : ''
    const uploadedLinkUrl = (() => {
        if (props.data === undefined) {
            return ""
        }
        const fileName = isContents(props.data)
            ? "lesson.json"
            : `${(props.data as Questions).baseKnowledgeTagId}.json`
        const ct = UrlHelper.normalizeUrl(fileName, props.data)
        return `${linkBaseUrl}${location.pathname}?ct=${encodeURIComponent(ct)}`
    })()

    return (
        <AppBar
            position="sticky"
            color={props.color}
        >
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: {xs: "none", md: "flex"},
                            fontFamily: "monospace",
                            fontWeight: 700,
                            letterSpacing: ".3rem",
                            color: "inherit",
                            textDecoration: "none",
                        }}
                    >
                        Mathmaji {props.title}
                    </Typography>
                    <Box sx={{flexGrow: 1, display: {xs: "flex", md: "flex"}}}>
                        <Button
                            onClick={() => {
                                setDialog("load")
                            }}
                            sx={{my: 2, color: "white", display: "block"}}
                        >Load
                        </Button>
                        <Button
                            onClick={props.exportContents}
                            sx={{my: 2, color: "white", display: "block"}}
                        >Export
                        </Button>
                        <Button
                            onClick={() => {
                                setDialog("clear")
                            }}
                            sx={{my: 2, color: "white", display: "block"}}
                        >Clear
                        </Button>
                        <Button
                            onClick={() => {
                                props.previewContents()
                                setDialog("preview")
                            }}
                            sx={{my: 2, color: "white", display: "block"}}
                        >preview
                        </Button>
                        <Button
                            onClick={() => {
                                linkDialogueRef.current?.open()
                            }}
                            sx={{my: 2, color: "white", display: "block"}}
                        >Go to
                        </Button>
                    </Box>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleExplorer}
                        edge="end"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </Container>
            <UploadDialog
                open={dialog=== "load"}
                onClose={() => closeDialog}
                onSubmit={(files) => {
                    const file = files[0]
                    file.text().then((text) => {
                        props.load(JSON.parse(text))
                        closeDialog()
                    })
                }}
            />
            <Dialog
                open={dialog === "clear"}
                onClose={closeDialog}
            >
                <DialogTitle>Clear working data.</DialogTitle>
                <DialogActions>
                    <Button
                        color="inherit"
                        onClick={closeDialog}>Cancel</Button>
                    <Button
                        color="error"
                        onClick={() => {
                            props.clear()
                            closeDialog()
                        }}>Clear</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={dialog === "clear"}
                onClose={closeDialog}
            >
                <DialogTitle>Clear working data.</DialogTitle>
                <DialogActions>
                    <Button
                        color="inherit"
                        onClick={closeDialog}>Cancel</Button>
                    <Button
                        color="error"
                        onClick={() => {
                            props.clear()
                            closeDialog()
                        }}>Clear</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={dialog === "preview"}
                onClose={closeDialog}
            >
                <Stack spacing={2} sx={{p:4}} alignItems="center">
                    <QRCode value={props.previewUrl || ""}/>
                    <Typography>{props.previewUrl}</Typography>
                    <Box>
                        <Typography >previewのjsonをCGで開くURL</Typography>
                        <Typography>{previewLinkUrl}</Typography>
                    </Box>
                    <Box>
                        <Typography>githubにアップロードされたjsonをCGから開くURL</Typography>
                        <Typography>{uploadedLinkUrl}</Typography>
                    </Box>
                </Stack>
            </Dialog>
            <LinkDialogue ref={linkDialogueRef}/>
        </AppBar>
    )
}

export default CommonAppBar;
